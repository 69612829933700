/* CoverPageTypography.module.css */
.bodyText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: left;
}

.bodyTextP {
  margin: 0 0 4px;
  font-size: 14px;
}

.bodyTextH1 {
  font-size: 48px;
  margin: 4px 0 0;
  color: #3b5998;
  text-align: center;
}

.greetingText {
  text-align: center;
  font-size: 12px;
  color: #ff7f00;
  margin-top: 20px;
  font-weight: bold;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .bodyTextH1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .bodyTextH1 {
    font-size: 1.5rem;
  }
}
