/* CoverPageImage.module.css */
.imageContainer {
  display: flex; /* 이미지 중앙 정렬을 위한 flex 설정 */
  justify-content: center; /* 가로축 중앙 정렬 */
  margin-top: 20px; /* 상단 여백 설정 */
}

.disasterImage {
  max-width: 100%; /* 이미지 최대 너비를 부모 요소에 맞춤 */
  height: auto; /* 높이를 자동으로 설정해 비율 유지 */
  border-radius: 8px; /* 모서리를 둥글게 설정 */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}
