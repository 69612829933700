.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px; /* 첫 번째 세로선과 텍스트 사이의 간격 */
  position: relative; /* 두 번째 세로선 위치를 위해 */
}

.headerContainer::before,
.headerContainer::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #0c429b;
}

.headerContainer::before {
  /* 첫 번째 세로선 */
  left: 0;
}

.headerContainer::after {
  /* 두 번째 세로선 */
  left: 5px; /* 첫 번째 세로선과 두 번째 세로선 사이의 간격 */
}

.title {
  font-size: 16px;
  color: #0c429b;
  margin: 0;
}
