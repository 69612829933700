.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh; /* 뷰포트 높이의 5%를 아래 여백으로 설정 */
}

.footerText {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
}

.footerText p {
  margin: 4px 0; /* 텍스트 간격 조정 */
}

.organizationText {
  font-size: 14px;
  text-align: center;
  color: #333;
}

.orangeText {
  color: orange;
  font-weight: bold;
}

.blueText {
  color: blue;
  font-weight: bold;
}

/* 모바일 환경에서 폰트 크기 조절 */
@media (max-width: 480px) {
  .footerText {
    font-size: 10px; /* 모바일에서 폰트 크기 줄이기 */
  }
}
