.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100vh;
  padding: 20px;
}

.frameContainer {
  position: relative;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.frameImage {
  width: 100%;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "궁서", serif;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}
