/* CoverPageTable.module.css */
.infoSection {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.infoTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  border-top: 2px solid #0c429b;
  border-bottom: 2px solid #0c429b;
}

.infoTableTh {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.infoTableTd {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

/* 첫 번째 컬럼 스타일 */
.firstColumn {
  width: 30%;
  border-left: none;
}

/* 마지막 컬럼 스타일 */
.lastColumn {
  border-right: none;
}
