/* CommonButton.module.css */
.startButton {
  background-color: #0c429b;
  color: #fff;
  padding: 12px 24px;
  font-size: 1rem; /* rem 단위로 반응형 텍스트 크기 */
  font-weight: bold; /* 글씨를 굵게 */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 20vw; /* 뷰포트 너비의 20%로 설정 */
  max-width: 200px; /* 최대 너비 제한 */
  min-width: 140px; /* 최소 너비 제한 */
  text-align: center; /* 텍스트 가운데 정렬 */
}

.startButton:hover {
  background-color: #1a5dbd;
}

/* 태블릿 사이즈 이하일 때 */
@media (max-width: 768px) {
  .startButton {
    width: 50vw; /* 뷰포트 너비의 50%로 설정 */
  }
}

/* 모바일 사이즈 이하일 때 */
@media (max-width: 480px) {
  .startButton {
    width: 70vw; /* 뷰포트 너비의 70%로 설정 */
  }
}
