/* ReportSection.module.css */
.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px; /* 선과 텍스트 사이의 간격 */
  border-left: 5px solid #333; /* 왼쪽 검정색 세로선 */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.subtitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.subtitle {
  font-size: 16px;
  color: #555;
  margin: 0;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 8px; /* 라벨 간 간격 조정 */
}

.label {
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 14px;
}

.danger {
  background-color: #ff4d4d;
}

.warning {
  background-color: #ffaa00;
}

.caution {
  background-color: #ffd700;
}

.interest {
  background-color: #87ceeb;
}

.safe {
  background-color: #66cdaa;
}
