/* CoverPageDivider.module.css */
.divider {
  display: flex;
  width: 100%;
  height: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
}

/* 개별 섹션 스타일 지정 */
.sectionOrange {
  flex: 1; /* 오렌지색 너비 */
  background-color: #ff7f00;
}

.sectionGreen {
  flex: 2; /* 초록색 너비 */
  background-color: #7fc31c;
}

.sectionBlue {
  flex: 1; /* 파란색 너비 */
  background-color: #0c429b;
}
