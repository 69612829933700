/* CoverPageHeader.css */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoLeft {
  height: auto;
  max-width: 8%;
}

.schoolBox {
  background-color: #0c429b; /* 파란색 배경 */
  color: #ffffff; /* 흰색 텍스트 */
  padding: 5px 40px;
  border-radius: 20px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 태블릿 크기 이하 (768px 이하) */
@media (max-width: 768px) {
  .schoolBox {
    padding: 4px 30px; /* 패딩을 줄여서 크기 조정 */
    font-size: 20px; /* 폰트 크기 축소 */
    border-radius: 15px;
  }
}

/* 모바일 크기 이하 (480px 이하) */
@media (max-width: 480px) {
  .schoolBox {
    padding: 3px 20px; /* 패딩을 더 줄여서 크기 조정 */
    font-size: 16px; /* 폰트 크기 축소 */
    border-radius: 10px;
  }
}
