/* ExamPageQuestion.module.css */
.questionBlock {
  margin-bottom: 30px; /* 각 질문 블록 사이 간격 */
  padding: 20px; /* 내부 여백을 좌우로 넓혀 설정 */
  background-color: #ffffff; /* 배경색 흰색 */
  border-radius: 8px; /* 모서리를 둥글게 설정 */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

.questionBlockH2 {
  font-size: 18px; /* 글씨 크기 */
  color: #333; /* 글자색 설정 */
  margin-bottom: 15px; /* 아래쪽 여백 */
}

.questionBlockUl {
  list-style-type: none; /* 기본 목록 스타일 제거 */
  padding: 0; /* 패딩 제거 */
  margin: 0; /* 마진 제거 */
}

.questionBlockLi {
  margin-bottom: 10px; /* 목록 항목 간격 */
}

.questionBlockLabel {
  font-size: 16px; /* 라벨 글씨 크기 */
  color: #555; /* 글자색 설정 */
  display: flex; /* 라벨 내 요소들을 가로로 배치 */
  align-items: center; /* 요소 세로 정렬 */
  cursor: pointer; /* 마우스 커서를 포인터로 변경 */
}

.questionBlockInput {
  margin-right: 10px; /* 라디오 버튼과 텍스트 사이 간격 */
  accent-color: #0c429b; /* 라디오 버튼 색상 */
}
