.coverPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 794px; /* 데스크톱 기준 최대 너비 설정 */
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafc;
}

@media (max-width: 768px) {
  .coverPage {
    max-width: 100%; /* 모바일에서는 최대 너비를 100%로 설정 */
    padding: 10px; /* 모바일에서는 여백을 줄임 */
  }
}
