/* ReportPageBody.module.css */
.reportBody {
  padding: 20px;
}

.sectionAndChartsContainer {
  display: flex;
  flex-direction: column;
  width: 100%; /* ReportSection의 너비에 맞춤 */
}

.flexContainer {
  display: flex;
  justify-content: center; /* 두 컨테이너를 가운데로 정렬 */
  gap: 5px; /* 두 차트 사이 간격 조정 */
  margin-top: 20px;
}

@media (max-width: 768px) {
  .flexContainer {
    flex-direction: column; /* 핸드폰에서는 세로로 정렬 */
    align-items: center; /* 가운데 정렬 */
  }
}

.tableAndChartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* ReportSection의 너비에 맞춤 */
}

.tableContainer {
  width: 100%; /* tableContainer는 tableAndChartContainer의 너비를 차지 */
  margin-bottom: 20px;
}

.reportTable {
  width: 100%; /* 테이블이 컨테이너 너비에 꽉 차도록 */
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
}

/* 회색 배경을 위한 스타일 */
.grayBackground {
  background-color: #e0e0e0; /* 회색 배경색 */
}

.reportTable th,
.reportTable td {
  padding: 8px;
  border: 1px solid #ccc;
}

/* 차트를 중앙 정렬 및 크기 조정 */
.chartContainer {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
